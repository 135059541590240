  import React, { Component } from "react";
  import { Link } from "react-router-dom";
  import { Segment, List, Input, Form, Grid, Icon, Header, Button, Dropdown, Menu, Checkbox, Select, Label, Message, Table} from 'semantic-ui-react'
  import "./JSONEditor.css";
  import { invokeApig, listS3objects, uploadS3, deleteS3 } from "../libs/awsLib";
  import { formatDate } from "../libs/sharedFunctions";
  import AWS from "aws-sdk";
  import AddressBox from "./AddressBox";
import { FilePond } from 'react-filepond';
import { DocumentClient } from "aws-sdk/clients/dynamodb";

  export default class JSONEditor extends Component {

      constructor(props) {
        super(props);
        
        this.state = {
          selectedObjectKey: '', 
          selectedObject: {}, 
          isLoading: true, 
          object: this.props.object, 
          objectid: null, 
          documents: []
        };
        this.loadDocuments=this.loadDocuments.bind(this);
        this.handlePondFile = this.handlePondFile.bind(this)
      }   
      
      handlePondFile(error, file) {
        if (error) {
            console.log('Error uploading file.');
            return;
        }
        console.log('File added', file);
        this.props.onFileUpload()
    }
      
    componentDidMount()
    {
      if (this.props.entities)
        this.loadentities(this.props.entities);
      this.loadDocuments(this.props.parentid, this.props.objectid)
    }

    componentWillReceiveProps(nextProps)
    {
      if (nextProps.entities);
        this.loadentities(nextProps.entities);

      //update object and select customer
      if (this.props.objectid!==null && (this.props.objectid !== nextProps.objectid || this.props.rowid !== nextProps.rowid))
      {
        console.log(this.props.objectid,nextProps.objectid)
        this.setState({object: nextProps.object, selectedObjectKey: nextProps.toplevelentity, selectedObject: nextProps.object});
        this.loadDocuments(nextProps.parentid, nextProps.objectid)

      }
      

    }

    loadentities(targetentities)
    {
      targetentities.filter(entityname=>{return !('entity_'+entityname in this.state)}).map(entityname=>
        {
          invokeApig({path: "/entities/"+entityname, method: "GET"}).then((result)=>{this.setState({['entity_'+entityname]: result.data})}).catch(err=>{console.log(err)})
        })

    }


    loadDocuments(parentid, objectid)
    {
      // var s3 = new AWS.S3();
      // const _this=this;
      // var params = {
      //   Bucket: this.props.bucket,
      //   Prefix: this.props.documentPrefix+'_'+parentid+'/'+objectid+'/'
      //   };
      // console.log(params)
      // s3.listObjects(params, function(err, data) {
      //   if (err) 
      //     throw(err)
      //   else     
      //     _this.setState({documents: data.Contents})
      // })
      const _this=this;
      listS3objects(this.props.bucket, this.props.documentPrefix+'_'+parentid+'/'+objectid+'/').then(response=>
        {
          console.log(response);
          if ('Contents' in response)
            _this.setState({documents: response.Contents});
            else
            _this.setState({documents: []});
            //console.log('no documents here');
           })


    }
    deleteDocument(documentKey)
    {
      // var s3 = new AWS.S3();
      // const _this=this;
      // var params = {
      //   Bucket: this.props.bucket,
      //   Key: documentKey
      //   };
      // s3.deleteObject(params, function(err, data) {
      //   if (err) 
      //     throw(err)
      //   else     
      //   {
      //     _this.loadDocuments(_this.props.parentid, _this.props.objectid)
      //   }
    
      // })      
      deleteS3(this.props.bucket, documentKey).then(response=>{
        this.loadDocuments(this.props.parentid, this.props.objectid)
      })
    }
    

    render() {
      const addressFields=['subbuildingname', 'buildingname', 'buildingnumber', 'dependentthoroughfare', 'thoroughfare', 'doubledependentlocality', 'dependentlocality', 'posttown', 'county', 'postcode']
      const properCase=(s)=>
      {
        return s.charAt(0).toUpperCase() + s.slice(1); 
      }

      const renderobject=(object, entityname, id, objectKey)=>
      {
        
        if ('entity_' +entityname in this.state)
        {
        var entitydescription="";
        var entity=this.state['entity_' +entityname];
        if ('displayfields' in entity)
          entitydescription=entity.displayfields.map(fieldname=>{if (fieldname in object && fieldname in entity.fields) return formatField(fieldname, entity.fields[fieldname], object[fieldname])}).join(' ')
        
        if (!entitydescription)
          entitydescription=entity.description;

        const errors=this.props.errors.filter(error=>{return error.entitykey===objectKey})
        const warnings=this.props.warnings.filter(warning=>{return warning.entitykey===objectKey})
        
        if(this.props.toplevelentity&&this.props.documentUpload){
          var uploaderrors=this.props.errors.filter(error=>{return error.entitykey===objectKey+'.uploads'})
        }

        return (
          <List.Item key={objectKey} >
          <List.Icon name={this.state['entity_'+entityname].icon}/>
          <List.Content>
          <List.Header>
            <a className='seaglassblue' onClick={()=>{this.setState({selectedObjectKey: objectKey, selectedObject: object})}}>
            {this.state.selectedObjectKey===objectKey ? <Icon name='right triangle'/> : null}{entitydescription+' '}
            {errors.length>0 ? <Icon color='red' name='x'/> : warnings.length>0 ? <Icon color='yellow' name='exclamation'/> : null}
            </a>
            {/* {this.props.editmode &&  this.props.mergeentities.indexOf(entityname)>-1 && <a><Dropdown text=' | move to '><Dropdown.Menu><Dropdown.Item>Line 1</Dropdown.Item><Dropdown.Item>Line 2</Dropdown.Item></Dropdown.Menu></Dropdown></a>}
            {this.props.editmode && <a onClick={()=>{removeEntity(objectKey)}}> | remove</a>} */}
          </List.Header>
          {this.props.documentUpload&&entityname===this.props.toplevelentity&&<List.List><List.Item>
                  <List.Icon name='upload'/>
                  <List.Content>
                  <List.Header>
                  <a className='seaglassblue' onClick={()=>{this.setState({selectedObjectKey: "documentupload"})}}>
                  {this.state.selectedObjectKey==="documentupload" ? <Icon name='right triangle'/> : null}Upload Documents
                  {uploaderrors.length>0 ? <Icon color='red' name='x'/> : null}
                  </a>
                  </List.Header>
                  </List.Content>
                  </List.Item>
                  {this.props.warnings.length>0 ? <List.Item><List.Icon color='yellow' name='exclamation'/><List.Content><List.Header>
                  <a className='seaglassblue' onClick={()=>{this.setState({selectedObjectKey: "Warning"})}}>
                  {this.state.selectedObjectKey==="Warning" ? <Icon name='right triangle'/> : null}Warnings
                  </a>
                  </List.Header></List.Content></List.Item> : null}
                  {this.props.errors.length>0 ? <List.Item><List.Icon color='red' name='x'/><List.Content><List.Header>
                  <a className='seaglassblue' onClick={()=>{this.setState({selectedObjectKey: "Error"})}}>
                  {this.state.selectedObjectKey==="Error" ? <Icon name='right triangle'/> : null}Errors
                  </a>
                  </List.Header></List.Content></List.Item> : null}
                  </List.List>}
          {/* <List.Description>{}</List.Description> */}
          {Object.keys(object).filter(key=>{return Array.isArray(object[key])}).map(key=>
          {
            const subentityname=key.slice(0,-1);
            var subentityplural=subentityname+"s"
            if ('entity_' +subentityname in this.state)
            {
              var subentity=this.state['entity_' +subentityname];
              if ('plural' in subentity)
                subentityplural=subentity.plural;
              else
              subentityplural=subentity.description+'s';
            }
            // var addentity=false;
            // if (this.props.editmode)
            //   if (this.props.addentities)
            //     if (subentityname in this.props.addentities)
            //       addentity=true;
            
            return (
              <List.List>
                <List.Item>
                <List.Icon name='folder'/>
                <List.Content>
            <List.Header>
            {subentityplural}
            {/* {addentity && <a onClick={()=>{addSubEntity(objectKey, key, this.props.addentities[subentityname])}}> add</a>} */}
            </List.Header>
                
                <List.List>

                {object[key].map((subobject, j)=>
                  {
                    return (renderobject(subobject, key.slice(0,-1), j, objectKey+'.'+key+':'+j))
                  })}
                  </List.List>
                </List.Content>    
                </List.Item>
              </List.List>)
          })}
          </List.Content>
          </List.Item>
        )
        }
      }
      
      const updateJSONField=(key, fieldname, value)=>
      {
        // var object=Object.assign({}, this.state.selectedObject);
        var object=Object.assign({}, this.state.object);
        // var selectedObject=Object.assign({}, this.state.selectedObject);
        var subobject=getObjectByKey(object, key);
        subobject[fieldname]=value;
        // selectedObject[fieldname]=value;
        this.setState({object: object});
        this.props.onDataChange(this.props.objectid, object)
          // this.forceUpdate()
      }

      const updateSubObjectField=(key, subobjectname, propertyname, value)=>
      {
        // var object=Object.assign({}, this.state.selectedObject);
        var object=Object.assign({}, this.state.object);
        // var selectedObject=Object.assign({}, this.state.selectedObject);
        var subobject=getObjectByKey(object, key);
        subobject[subobjectname][propertyname]=isNaN(value)? value : Number(value);
        // selectedObject[fieldname]=value;

        if (subobjectname=='prices' && value.length==0)
          delete subobject[subobjectname][propertyname]

        this.setState({object: object});
        this.props.onDataChange(this.props.objectid, object)
          // this.forceUpdate()
      }

      

      const updateAddress=(subbuildingname, buildingname, buildingnumber, dependentthoroughfare, thoroughfare, doubledependentlocality, dependentlocality, posttown, county, postcode)=>
      {
        // var object=Object.assign({}, this.state.selectedObject);
        var object=Object.assign({}, this.state.object);
        // var selectedObject=Object.assign({}, this.state.selectedObject);
        var subobject=getObjectByKey(object, this.state.selectedObjectKey);
        subobject['subbuildingname']=subbuildingname
        subobject['buildingname']=buildingname
        subobject['buildingnumber']=buildingnumber
        subobject['dependentthoroughfare']=dependentthoroughfare
        subobject['thoroughfare']=thoroughfare
        subobject['doubledependentlocality']=doubledependentlocality
        subobject['dependentlocality']=dependentlocality
        subobject['posttown']=posttown
        subobject['county']=county
        subobject['postcode']=postcode
        
        // selectedObject[fieldname]=value;
        this.setState({object: object});
        this.props.onDataChange(this.props.objectid, object)
          // this.forceUpdate()
      }


      const formatField=(fieldname, field, value)=>
      {
          if (value)
          {
          if ('lookup' in field && 'lookupvalues' in field)
          {
            const lookupvalue=field.lookupvalues.find(option=>{return option.value===value})
            if (lookupvalue)
            return lookupvalue.text
            else
            return ''
          }
          else
          {
            if (field.type==='date')
              return formatDate(new Date(value))
            else if (field.type==='bit')
              return value ? "True" : "False"
            else
              return value.toString();
          }
        }            
      }

      const addSubEntity=(parentKey, subObjectName, entityData)=>
      {
        console.log('addSubEntity',parentKey, subObjectName, entityData)
        var object=Object.assign({}, this.state.object);
        var subobject=Object.assign({}, getObjectByKey(object, parentKey));
        // use JSON.parse(JSON.stringify(object)) to produce a disconnected deep copy so the new entity doesn't mimic the old
        var newEntityData=JSON.parse(JSON.stringify(entityData));
        subobject[subObjectName].push(newEntityData)
        this.setState({object: object});
        this.props.onDataChange(this.props.objectid, object)
      }
  
      const getObjectByKey=(object, key)=>

      {
      return key.split('.').reduce((subobject, subkey)=>
          {
            // console.log(subkey, JSON.stringify(subobject))
              if (subkey===this.props.toplevelentity)
              return subobject
            else 
            {
              const parseobjectkey=subkey.split(':');
              return subobject[parseobjectkey[0]][parseInt(parseobjectkey[1])]
            }
          }, object)
          // return subobject;
      }

      const removeEntity=(key)=>
      {
        if (key===this.props.toplevelentity)
          //top level - remove entire object
          this.props.onRemove(this.props.objectid)
        else
        {
          //remove subentity
          var object=Object.assign({}, this.state.object);
          const parentKey=key.split('.').slice(0,-1).join('.')
          var parentObject=getObjectByKey(object, parentKey)
          const removeKey= key.split('.').slice(-1)[0]
          const parseRemoveKey=removeKey.split(':');
          const removeArray=parentObject[parseRemoveKey[0]];
          const removeIndex=parseInt(parseRemoveKey[1]);
          console.log(removeArray, removeIndex)
          removeArray.splice(removeIndex, 1);
          console.log(removeArray)
          this.setState({object: object});
          this.props.onDataChange(this.props.objectid, object)
        }
      }      

      const mergeEntity=(objectkey, targetindex)=>
      {
        this.props.onMerge(this.props.objectid, objectkey, targetindex);
        removeEntity(objectkey);
      }


      const renderfield=(fieldname, field, value, key, fielderrors, fieldwarnings)=>
      {
        const fieldkey=this.props.objectid.toString()+'.'+key+'.'+fieldname
        var renderfield;
        // console.log('renderfield',field)

        if ('lookup' in field)
        {
          renderfield=<Select key={fieldkey} placeholder={field.label} className="inputfield" disabled={!this.props.editmode} options={field.lookupvalues} value={value} onChange={(event, data)=>{updateJSONField(key, fieldname, data.value)}}/>
        }
        else
        {
          if (field.type==='varchar')
            renderfield=<Input key={fieldkey} placeholder={field.label} className="inputfield" disabled={!this.props.editmode} type='text' maxLength={field.length} value={value} onChange={(event, data)=>{updateJSONField(key, fieldname, data.value)}}/> 
          else if (field.type==='int')
            renderfield=<Input key={fieldkey} placeholder={field.label} type='number' step="1" disabled={!this.props.editmode} maxLength={field.length} className="inputfield"  value={value} onChange={(event, data)=>{updateJSONField(key, fieldname, parseInt(data.value))}}/> 
          else if (field.type==='decimal') {
            // console.log('renderfield decimal',field)
            renderfield=<Input key={fieldkey} placeholder={field.label} type='number' disabled={!this.props.editmode} step={Math.pow(10, -field.scale)} maxLength={field.precision} className="inputfield" value={value} onChange={(event, data)=>{updateJSONField(key, fieldname, parseFloat(data.value))}}/> 
          }
          else if (field.type==='date')
            renderfield=<Input key={fieldkey} placeholder={field.label} type='date' disabled={!this.props.editmode} className="inputfield" maxLength={field.length} value={value} onChange={(event, data)=>{updateJSONField(key, fieldname, data.value)}}/> 
          else if (field.type==='datetime')
            renderfield=<Input key={fieldkey} placeholder={field.label} type='datetime-local' disabled={!this.props.editmode} className="inputfield" maxLength={field.length} value={value} onChange={(event, data)=>{updateJSONField(key, fieldname, data.value+':00')}}/> 
          else if (field.type==='bit')
            renderfield=<Checkbox key={fieldkey} label={field.label} disabled={!this.props.editmode} className="inputfield" value={value} onChange={(event, data)=>{updateJSONField(key, fieldname, data.value)}}/>
          }
          if (renderfield)
          {
            return <Form.Field required={!field.nullable}>
              {(field.type!=='bit') ? <label>{field.label}</label> : null}
              {renderfield}
              {fielderrors.length>0 || fieldwarnings.length>0 ? <Label pointing><Icon name={fielderrors.length>0 ? 'x' : 'exclamation'}/>{fielderrors.concat(fieldwarnings).map(error=>{return error.message}).join('; ')}</Label> : null}

            </Form.Field>
          }
      }
    
      const getAddressFieldValue=(object, fieldname)=>
      {
        if (fieldname in object)
          return object[fieldname]
        else
          return ''

      }

      const manualFunction=(key, functiondef)=>
    {
        if (functiondef.function==='addsubentity')
        {
          addSubEntity(key, functiondef.parameters[0], functiondef.parameters[1])
        }

        if (functiondef.function==='copydetails')
        {
          var object=Object.assign({}, this.state.object);
          var subobject=getObjectByKey(object, key);
          
          var fromobject=getObjectByKey(object, functiondef.parameters[0]); 
          functiondef.parameters[1].map(fieldname=>{subobject[fieldname]=fromobject[fieldname]})
          this.setState({object: object});
          this.props.onDataChange(this.props.objectid, object)

  
          
        }


    }

      const renderfields=(selectedobjectkey)=>
      {
        if (selectedobjectkey)
        {
          var selectedobject=getObjectByKey(this.state.object, selectedobjectkey)
          if (selectedobject)
          {
          var entityname="";
          var entityinstance=1;
          if (selectedobjectkey===this.props.toplevelentity)
            entityname=selectedobjectkey
          else
          {
            const parsekey=selectedobjectkey.split('.');
            const subobjectkey=parsekey[parsekey.length-1];
            entityname=subobjectkey.split(':')[0].slice(0,-1)
            entityinstance=parseInt(subobjectkey.split(':')[1])+1
          }

          if ('entity_'+entityname in this.state)
          {
            const entity=this.state['entity_'+entityname]
            var entitydescription=""
            if ('displayfields' in entity)
              entitydescription=entity.displayfields.map(fieldname=>{if (fieldname in selectedobject  && fieldname in entity.fields) return formatField(fieldname, entity.fields[fieldname], selectedobject[fieldname])}).join(' ')
            
            const errors=this.props.errors.filter(error=>{return error.entitykey===selectedobjectkey})
            const entityerrors=errors.filter(error=>{return error.fieldname===null}).map(error=>{return error.message})
            const warnings=this.props.warnings.filter(warning=>{return warning.entitykey===selectedobjectkey})
            const entitywarnings=warnings.filter(warning=>{return warning.fieldname===null}).map(warning=>{return warning.message})
            
            var entityfields=[];
            if (entityname in this.props.fields)
              entityfields=this.props.fields[entityname];

            var buttons=[];
            const evaluateFunctionCondition=(object, functiondef)=>
            {
              if ('condition' in functiondef)
              {
                const condition=functiondef.condition;
                if (condition.includes('='))
                {
                  const parsecondition=condition.split('=');
                  const conditionfield=parsecondition[0];
                  const conditionvalue=parsecondition[1];
                  if (conditionfield in object)
                  {
                    return object[conditionfield].toString()===conditionvalue
                  }
                  else
                    return false
                }
                else
                return false
              }
              else
                return true
            }


            if (entityname in this.props.manualfunctions)
            {
              buttons=this.props.manualfunctions[entityname].filter(functiondef=>{return evaluateFunctionCondition(selectedobject, functiondef)}).map(functiondef=>{return <Button className='seaglassbutton' size='small' onClick={()=>{manualFunction(selectedobjectkey, functiondef)}}><Icon name={functiondef.icon}/>{functiondef.text}</Button>})
            }
            buttons.push(<Button size='small' className='seaglassbutton' onClick={()=>{removeEntity(selectedobjectkey)}}><Icon name='remove'/>Remove</Button>);

            return <Form>
            <Header as='h3'className='seaglassblue'><Icon size='tiny' name={entity.icon}/>{entity.description + ' ' + entityinstance + ': ' + entitydescription}</Header>
            {this.props.editmode && <Menu>
            {/* {Object.keys(selectedobject).filter(key=>{return Array.isArray(selectedobject[key])  }).map(key=>
              {
                const subentityname=key.slice(0,-1);
                const entity=this.state['entity_'+subentityname]
                if (subentityname in this.props.addentities)
                  return <Button size='small' primary onClick={()=>{addSubEntity(selectedobjectkey, key, this.props.addentities[subentityname])}}><Icon name='add'/>Add {entity.description}</Button>
              }) } */}

            {buttons}
            {this.props.mergeentities.indexOf(entityname)>-1 && this.props.mergetargets.length> 1 && <Button className='seaglassbutton' size='small'><Icon name='share'/><Dropdown text='Move to '><Dropdown.Menu>{this.props.mergetargets.filter((description, i)=>{return i!==this.props.objectid}).map((description, i)=>{return <Dropdown.Item onClick={()=>{mergeEntity(selectedobjectkey, i)}}>{description}</Dropdown.Item>})}</Dropdown.Menu></Dropdown></Button>}
            </Menu>}
            
            {entityerrors.length>0 && <Message visible error list={entityerrors}/>}
            {entitywarnings.length>0 && <Message visible warning list={entitywarnings}/>}
            
            {//show fields
              entityfields.map(fieldname=>
              {
                var value=null;
                const fielderrors=errors.filter(error=>{return error.fieldname===fieldname})
                const fieldwarnings=warnings.filter(warning=>{return warning.fieldname===fieldname})


                //suppress address fields and show address box instead of post code
                if (addressFields.indexOf(fieldname)>-1)
                {
                  if (fieldname==='postcode')
                    return <Form.Field><label>Address</label><AddressBox subbuildingname={getAddressFieldValue(selectedobject, 'subbuildingname')} buildingname={getAddressFieldValue(selectedobject, 'buildingname')} buildingnumber={getAddressFieldValue(selectedobject, 'buildingnumber')} dependentthoroughfare={getAddressFieldValue(selectedobject, 'dependentthoroughfare')} thoroughfare={getAddressFieldValue(selectedobject, 'thoroughfare')} doubledependentlocality={getAddressFieldValue(selectedobject, 'doubledependentlocality')} dependentlocality={getAddressFieldValue(selectedobject, 'dependentlocality')} posttown={getAddressFieldValue(selectedobject, 'posttown')} county={getAddressFieldValue(selectedobject, 'county')} postcode={getAddressFieldValue(selectedobject, 'postcode')} onChange={updateAddress} enabled={this.props.editmode} /></Form.Field>

                }
                else
                {
                  if (fieldname in selectedobject)
                    value=selectedobject[fieldname];

                  return renderfield(fieldname, entity.fields[fieldname], value, selectedobjectkey, fielderrors, fieldwarnings);
                }
                  
              })}
              {//show subobjects
              entityname in this.props.showsubobjects&&
                this.props.showsubobjects[entityname].map(subobjectname=>
                  {
                    const subobjectkey=entityname+'.'+subobjectname;
                    const objectFields=this.props.columns.filter((column)=>{return column.storeinsubobject && column.targetfields.indexOf(subobjectkey)>-1})
                    return objectFields.map(column=>{
                      var subFieldkey = this.state.selectedObjectKey +  '_' + column.name;
                      const value=selectedobject[subobjectname][column.name];
                      const fielderrors=errors.filter(error=>{return error.fieldname===column.name})
                      const fieldwarnings=warnings.filter(warning=>{return warning.fieldname===column.name})
                      var renderField;
                      const dataType=column.format.split(':')[0];
                      const dataLen=column.format.split(':')[1];
                      if (dataType==='text')
                        renderField=<Input key={subFieldkey} type='text' maxLength={dataLen} disabled={!this.props.editmode} placeholder={column.name} value={value} onChange={(event, data)=>{updateSubObjectField(selectedobjectkey, subobjectname, column.name, data.value)}}/>
                      else if (dataType==='integer')
                        renderField=<Input key={subFieldkey} type='number' maxLength={dataLen} step="1" disabled={!this.props.editmode} placeholder={column.name} value={value} onChange={(event, data)=>{updateSubObjectField(selectedobjectkey, subobjectname, column.name, data.value)}}/>
                      else if (dataType==='decimal')
                      renderField=<Input key={subFieldkey} type='number' maxLength={dataLen.split(','[0])} step={Math.pow(10, -parseInt(dataLen.split(','[1])))} disabled={!this.props.editmode} placeholder={column.name} value={value} onChange={(event, data)=>{updateSubObjectField(selectedobjectkey, subobjectname, column.name, data.value)}}/>
                      return <Form.Field>
                        <label>{column.name}</label>
                        {/* <Input type={isNaN(value)? 'text':'number' } disabled={!this.props.editmode} placeholder={propertyname} value={value} onChange={(event, data)=>{updateSubObjectField(selectedobjectkey, subobjectname, propertyname, data.value)}}/> */}
                        {renderField}
                        {fielderrors.length>0 || fieldwarnings.length>0 ? <Label pointing><Icon name={fielderrors.length>0 ? 'x' : 'exclamation'}/>{fielderrors.concat(fieldwarnings).map(error=>{return error.message}).join('; ')}</Label> : null}
                      </Form.Field> 
                      })                      
                    })
                  }
              </Form>
   
  


          }
        }
        }
      }
      const _this = this;
      const serveroptions={
        process: function(fieldName, file, metadata, load, error, progress, abort) 
        {
          // var s3 = new AWS.S3();
          // var params = {
          //   Bucket: _this.props.bucket,
          //   Key: _this.props.documentPrefix+'_'+_this.props.parentid+'/'+_this.props.objectid+'/'+file.name,
          //   Body: file
          // };
          const filename=file.name.replace(/[()]/g, '').replace(/[!]/g, '')
          uploadS3(_this.props.bucket, _this.props.documentPrefix+'_'+_this.props.parentid+'/'+_this.props.objectid+'/'+filename, file)
          .then(response=>{
            load(filename);
            _this.loadDocuments(_this.props.parentid, _this.props.objectid)
          })
          .catch(err=>{
            error('An error occurred uploading the file')
          })

          // s3.putObject(params, function(err, data) {
          //   console.log(err,data)
          //   if (err) 
          //   {
          //   }
          //   else     
          //   {
          //     load(file.name);
          //     _this.loadDocuments(_this.props.parentid, _this.props.objectid)
          //   }
        
          // })
          }
          }

      const documentUpload=(entityname)=>{
        var uploaderrors=this.props.errors.filter(error=>{return error.entitykey===entityname}).map(error=>{return error.message})
      return (
      <div>
      <Header><Icon name='upload'/>Upload Documents</Header>
      {uploaderrors.length>0 && <Message visible error list={uploaderrors}/>}
        {this.props.editmode && <FilePond allowMultiple={true} allowRevert={false} server={serveroptions} onprocessfile={this.handlePondFile}/>}
      <List>
        {this.state.documents.map(document=>
        <List.Item>
                <List.Icon name='file'/>
                <List.Content>
            <List.Header>{document.Key.replace(this.props.documentPrefix+'_'+this.props.parentid+'/'+this.props.objectid+'/', '')}</List.Header>
            {this.props.editmode && <a onClick={()=>{this.deleteDocument(document.Key)}}>delete</a>}
            </List.Content>
        </List.Item>
        )}
      </List>
      </div>
      )}

      const formatEntityKey=(entitykey)=>{
        var entityString=[]
        var newkey = entitykey.split(".")
        newkey.forEach(function (part, index) {
          var entity = part.split(":")
          if (entity.length===2) {entityString.push(entity[0].charAt(0).toUpperCase()+entity[0].slice(1,-1)+' '+(parseInt(entity[1])+1))}
          else {entityString.push(entity[0].charAt(0).toUpperCase()+entity[0].slice(1))}
        })
        if (entityString.length!==1) entityString=entityString.slice(1)
        return entityString.join(" - ").replace("meterpoint"," Meter Point")
      }

      const formatFieldName=(entitykey,fieldname)=>{
        if (fieldname){
          var entityname=entitykey.split(".").slice(-1)[0].split(":")[0]
          if (entityname.slice(-1)==="s") entityname=entityname.slice(0,-1)
          var entitydetails=this.state['entity_'+entityname]
          if (entitydetails) {
            if ('fields' in entitydetails) {
              var entityfields = entitydetails['fields']
              if (fieldname in entityfields) {
                return entityfields[fieldname].label
          }}}
          return fieldname.charAt(0).toUpperCase()+fieldname.slice(1)
        }
        return fieldname
      }

      const validationView=(type)=>{
      if (type==='Error') {
        var validations=this.props.errors
        var icon='x'
        var colour='red'
      } else {
        var validations=this.props.warnings
        var icon='exclamation'
        var colour='yellow'
      }

      return (
      <div>
      <Header><Icon color={colour} name={icon}/>{type}</Header>
      <Table color={colour} compact>
        <Table.Header>
          <Table.Row>
            </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Entity</Table.HeaderCell>
            <Table.HeaderCell>Field</Table.HeaderCell>
            <Table.HeaderCell>Detail</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {validations.map((validation, i) =>
        {return <Table.Row>
                <Table.Cell>{formatEntityKey(validation.entitykey)}</Table.Cell>
                <Table.Cell>{formatFieldName(validation.entitykey,validation.fieldname)}</Table.Cell>
                <Table.Cell>{validation.message}</Table.Cell>
                </Table.Row>
        })}
        </Table.Body>
      </Table>
      </div>
      )}

      return (
        <div className='jsonedit'>
          <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={6}>
            <div className='jsonobjects'>
            <List attached='top'>

            {renderobject(this.state.object, this.props.toplevelentity, 0, this.props.toplevelentity)}
            </List>
            </div>
            </Grid.Column>
            <Grid.Column width={10}>
            <div className='jsonfields'>
            {this.state.selectedObjectKey==='documentupload' ? documentUpload('customer.uploads') : this.state.selectedObjectKey==='Warning' ? validationView('Warning') : this.state.selectedObjectKey==='Error' ? validationView('Error') : renderfields(this.state.selectedObjectKey, this.state.selectedObject)}
            </div>
            </Grid.Column>
          </Grid.Row>
          </Grid>
        </div>
      );
    }
  }